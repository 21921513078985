<template>
  <detail-layout-with-pic header="Areas Covered">
    <template v-slot:text-col>
      <div class="flex flex-wrap -m-4">
        <div class="flex flex-col w-full p-4">
          <div class="flex -m-4">
            <div class="w-full md:w-1/2 pb-0 p-4">
              <ul>
                <li v-for="(area, index) in areasList1" :key="index">
                  {{ area }}
                </li>
              </ul>
            </div>
            <div class="w-full md:w-1/2 pb-0 p-4">
              <ul>
                <li v-for="(area, index) in areasList2" :key="index">
                  {{ area }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:pic-col>
      <img
        src="../assets/images/map.jpg"
        width="474"
        height="474"
        alt="Areas covered map"
      />
    </template>
    <div class="mb-8 sm:mb-14">
      <p>
        We can now also offer a service within Chichester, Ringwood, Verwood,
        Ferndown and nearby areas.
      </p>
      <p>
        Our vestibular service covers Southern Hampshire and West Sussex.
        (Travel charges may be applicable).
      </p>
    </div>
    <div class="mb-14 sm:mb-20">
      <h2 class="mb-5">Community Setting</h2>
      <p>
        We have chosen to treat people in their own home or in a community
        setting. Evidence shows that this is a very effective way to treat and
        rehabilitate neurological conditions and can enhance progress.
      </p>
      <p>We shall tailor our treatment to focus on agreed goals.</p>
      <p>
        We also have physiotherapy/ rehabilitation assistants who will be
        working alongside the physiotherapists to practise and repeat activity
        and exercise programmes. We work closely with a personal trainer who
        will assist in improving your fitness and condition.
      </p>
      <p>Your program will be regularly reviewed and progressed as required.</p>
      <p>
        This might help to increase frequency of treatments while keeping your
        costs under control.
      </p>
    </div>
    <template v-slot:feature-cols>
      <div class="flex flex-wrap -m-4">
        <feature-3-cols
          :icon="['far', 'user']"
          title="Meet the team"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Meet The Team"
        />
        <feature-3-cols
          :icon="['far', 'heart']"
          title="Our commitment to you"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Our Commitment To You"
        />
        <feature-3-cols
          :icon="['far', 'map']"
          title="Areas covered"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Areas Covered"
        />
      </div>
    </template>
  </detail-layout-with-pic>
</template>

<script>
import DetailLayoutWithPic from "../layouts/DetailLayoutWithPic.vue";
import Feature3Cols from "../components/Feature3Cols.vue";

export default {
  name: "Areas Covered",
  components: {
    "detail-layout-with-pic": DetailLayoutWithPic,
    "feature-3-cols": Feature3Cols,
  },
  data() {
    return {
      areasList1: [
        "Chandlers Ford",
        "Clanfield",
        "Cowplain",
        "Denmead",
        "Eastern Southampton",
        "Emsworth",
        "Fareham",
        "Gosport",
        "Hamble",
        "Hayling Island",
      ],
      areasList2: [
        "Hedge End and Bishops Waltham",
        "Portsmouth",
        "Sarisbury Green and Locksheath",
        "Soberton",
        "Ringwood",
        "Warsash",
        "Waterlooville",
        "Wickham",
        "Whiteley",
      ],
    };
  },
};
</script>
